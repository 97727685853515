.page_name {
    color: #0E294B;
    font-size: 3.3rem;
    text-align: center;
    font-weight: bolder;
    font-family: "Avenir Next", sans-serif;
    margin-bottom: 70px;
    margin-top: 20px;
}

.page_content {
    width: 80%;
    margin: 0 auto;
    background: white;
    font-family: "Avenir Next", sans-serif;
}

@media screen and (max-width: 900px ){
    .page_content {
        width: 98%;
    }
    .page_name {
        font-size: 2.3rem;
        margin-bottom: 50px;
    }
}
