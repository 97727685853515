.react-tabs__tab-list { display: flex; align-items: center; justify-content: center; border-bottom: 1px solid #aaa; margin: 0 0 10px; padding: 0; }
.react-tabs__tab { display: inline-block; border: 1px solid transparent; border-bottom: none; bottom: -1px; position: relative; list-style: none; padding: 6px 12px;
    cursor: pointer; font-family: "Avenir Next", serif; color: #0E294B; font-size: 1.5rem}
.react-tabs__tab--selected { background: #fff; border-color: #aaa; color: black; border-radius: 5px 5px 0 0; }
.react-tabs__tab-panel { display: none; font-family: "Avenir Next", serif; font-size: 1.5rem}
.react-tabs__tab-panel--selected { display: block; }
.react-tabs__tab--disabled { color: GrayText; cursor: default; }
.react-tabs__tab:focus { box-shadow: 0 0 5px #0E294B; border-color: hsl(208, 99%, 50%); outline: none; }
.react-tabs__tab:focus:after { content: ""; position: absolute; height: 5px; left: -4px; right: -4px; bottom: -5px; background: #fff; }

table {
    border-spacing: 14vw 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    margin-bottom: 40px;
}

tr { box-shadow: 0 1px 0 0 rgba(0,0,0,0.99); }
td {padding-top: 10px;}

td br {
    display: none;
}

.schedule__time {
    font-weight: bolder;
}

.select_schedule {
    display: none;
}

.__select {
    margin-bottom: 30px;
}

@media screen and (max-width: 900px){
    .select_schedule {
        display: initial;
    }
    .react-tabs__tab-list {
        display: none;
    }
    td .aud {
        display: initial;
    }
}

@media screen and (max-width: 700px) {
    td .class {
        display: initial;
    }
    td {
        font-size: 1.2rem;
    }
}

