.study_materials {
    background-color: #0E294B;
    background-size: cover;
    background-position: center;
    margin-bottom: 60px;
    margin-top: 30px;
    display: flex;
    flex-flow: column;
    color: white;
    padding: 30px;
}

.study_books {
    padding-left: 10%;
    padding-right: 10%;

}

.study_book {
    display: flex;
    flex-flow: column;
    padding-bottom: 10px;
    margin-top: 50px;
    border-bottom: 0.5px solid gray;
}

.book_name {
    font-size: 1.2rem;
    padding-left: 5px;
}

.icon_and_link {
    display: flex;
    flex-flow: row;
    margin-top: 11px;
    padding-left: 20px;
}

.book_image {
    width: 30px;
    height: 30px;
}

.book_link {
    font-size: 0.8rem;
    margin-left: 20px;
    margin-top: 12px;
    color: white;
}