.review {
    width: 300px;
    height: 700px;
    border-radius: 6px;
    border: 1px solid #DCDCDC;
    padding: 20px 30px;
    font-family: "Avenir Next", serif;
    overflow: scroll;
    margin-bottom: 30px;
}

.review_header {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.review_image {
    width: 50px;
    height: 50px;
}


.review_content {
    font-size: 1.1rem;
    line-height: 1.25
}