.news {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.news_div {
    width: 900px;
    display: flex;
    border: solid 1px #DCDCDC;
    text-decoration: none;
    border-radius: 8px;
    margin-bottom: 60px;
}

.news_title:hover {
    color: #0E294B
}

.news_brief:hover {
    color: #16227f
}

.news_photo_div {
    width: 50%;
}
.news_div:hover .news_title{
    color: blue;
}

.news_photo {
    width: 100%;
}

.news_info {
    width: 50%;
    padding-left: 20px;
    padding-top: 30px;
    font-family: "Avenir Next", serif;
}


.news_date {
    color: gray;
    font-size: 0.8rem;
    font-family: sans-serif;
}

.news_title {
    margin-top: 10px;
    font-size: 1.5rem;
    color: black;
}

.news_brief {
    color: black;
    margin-top: 30px;
    font-size: 0.9rem;
    line-height: 1.2;
    font-family: "Avenir Next", serif;
}

.pagination {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    width: 80%;
    margin-bottom: 40px;
}

.page-item {
    list-style-type: none;
    padding-left: 20%;
    font-size: 1.3rem;
}

.page-link {
    text-decoration: none;
    color: #0E294B;
    border: 1px solid;
    border-radius: 100%;
    padding: 10px;
}

@media screen and (max-width: 900px) {
    .news_div {
        flex-flow: wrap;
        width: 350px;
    }
    .news_photo_div {
        width: 100%;
    }
    .news_info {
        width: 100%;
    }
    .page-item {
        list-style-type: none;
        padding-left: 10%;
        font-size: 1.1rem;
    }
}
