.news_home {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.title_home {
    margin-top: 20px;
    width: 100%;
}

.news_grid_home {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    justify-items: center;
}

.news_div_home {
    display: flex;
    flex-flow: column;
    width: 90%;
    border: solid 1px #DCDCDC;
    text-decoration: none;
    border-radius: 8px;
    margin-bottom: 60px;
}


.news_grid_home .news_photo_div {
    width: 100%;
}

.news_grid_home .news_info {
    width: 100%;
    padding-left: 20px;
    padding-top: 30px;
    font-family: "Avenir Next", serif;
    padding-bottom: 20px;
}

.all_link {
    color: gray;
    padding-top: 9px;
    padding-right: 30px;
}

.title_home {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;
    font-family: "Avenir Next", sans-serif;
    margin-bottom: 10px;
    width: 100%;
}

.home_gallery_img_div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 700px;
    object-fit: cover;
}
.home_gallery_title {
    font-family: "Avenir Next", sans-serif;
    position: absolute;
    bottom: 90px;
    text-align-last: center;
    color: white;
    text-shadow: 1px 0 1px #0E294B,
    0 1px 1px #0E294B,
    -1px 0 1px #0E294B,
    0 -1px 1px #0E294B;
    text-align: center;
    font-size: 1.6rem;
    padding-left: 20%;
    padding-right: 20%;
    line-height: 1.4;
}

.home_gallery_link {
    position: absolute;
    bottom: 40px;
    font-size: 1.2rem;
}

.review_home {
    width: 85%;
    border: 1px solid #DCDCDC;
    border-radius: 8px;
    padding: 20px;
}


@media screen and (max-width: 630px){
    .news_grid_home {
        grid-template-columns: 1fr;
    }

    .home_gallery_title {
        font-size: 1.3rem;
    }

    .home_gallery_link {
        font-size: 1rem;
    }

    .review_home {
        height: 380px;
        padding: 10px;
    }
}


